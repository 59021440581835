.bodycon {
    width: 100vw;
    height: 90.550vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    font-family: 'Bahnschrift', ui-monospace;
}

.nameText {
    text-transform: capitalize;
    font-size: 3.14vh;
    color: red;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.desc {
    text-transform: capitalize;
    color: red;
    position: absolute;
    top: 52.5%
}

.desc {
    font-size: 1.5vh;
}