.elementsheader {
    background: black;
    display: flex;
    flex-direction: column;
}

.navBar {
    background: transparent;
    font-family: 'Bahnschrift', ui-monospace;
    font-size: 16px;
}

.Home {
    background: black;
    border-style: hidden;
    border-color: white;
    text-align: left;
    color: white;
    margin: 10px 10px;
    cursor: pointer;
    text-transform: capitalize;
    transition: ease white 250ms;
    border-radius: 5px;
    font-family: Bahnschrift, ui-monospace;
    font-size: 2vh;
}

.Camera {
    background: black;
    border-style: hidden;
    border-color: white;
    text-align: left;
    color: white;
    margin: 10px 10px;
    cursor: pointer;
    text-transform: capitalize;
    transition: ease white 250ms;
    border-radius: 5px;
    font-family: Bahnschrift, ui-monospace;
    font-size: 2vh;
}

.Investigates {
    background: black;
    border-style: hidden;
    border-color: white;
    text-align: left;
    color: white;
    margin: 10px 10px;
    cursor: pointer;
    text-transform: capitalize;
    transition: ease white 250ms;
    border-radius: 5px;
    font-family: Bahnschrift, ui-monospace;
    font-size: 2vh;
}

.Home:hover {
    /*//text-decoration: underline;*/
    text-decoration: underline;
    text-decoration-thickness: from-font;
}

.Camera:hover {
    /*//text-decoration: underline;*/
    text-decoration: underline;
    text-decoration-thickness: from-font;
}

.Investigates:hover {
    /*//text-decoration: underline;*/
    text-decoration: underline;
    text-decoration-thickness: from-font;
}
