.blogCon {
    width: 100vw;
    height: 90.550vh;
    display: flex;
    flex-direction: column;
    background-color: lightgrey;
    font-family: 'Bahnschrift', ui-monospace;
}

.blogText {
    text-transform: capitalize;
    font-size: 3.776vw;
    color: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}