.gridcon {
    padding-top: 20px;
    background: antiquewhite;
}

.cameraConCon {
    position: relative;
    justify-content: center;
    align-items: center;
    offset: auto;
}